import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Icon, InlineIcon } from "@iconify/react"
import closeLine from "@iconify/icons-clarity/close-line"

import "./burger.scss"

export default function Burger({ open, setOpen }) {
  let styleVariable = !open ? "closeMenu" : "openMenu"
  return (
    <div id="burger_open" open={open} className={styleVariable}>
      <div id="exit_button" onClick={() => setOpen(!open)}>
        <Icon
          icon={closeLine}
          style={{
            fontSize: "30px",
            float: "right",
            padding: "30px 30px",
            color: "#6d6d6d",
          }}
        />
      </div>
      <ul>
        <li onClick={() => setOpen(!open)}>
          <AnchorLink to="/#main_company" onClick={() => setOpen(!open)}>
            公司簡介
          </AnchorLink>
        </li>
        <li onClick={() => setOpen(!open)}>
          <AnchorLink to="/service" onClick={() => setOpen(!open)}>
            貸款服務
          </AnchorLink>
        </li>
        <li onClick={() => setOpen(!open)}>
          <AnchorLink to="/application" onClick={() => setOpen(!open)}>
            申請辦法
          </AnchorLink>
        </li>
        <li onClick={() => setOpen(!open)}>
          <AnchorLink to="/#main_contact" onClick={() => setOpen(!open)}>
            聯絡我們
          </AnchorLink>
        </li>
      </ul>
    </div>
  )
}
