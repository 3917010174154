import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./footer.scss"

export default function Footer() {
  return (
    <div id="footer">
      <div id="footer_left" className="footer_columns">
        <span>
          <p>
            <AnchorLink to="/term">免責聲明</AnchorLink>
            &nbsp;|&nbsp;
            <AnchorLink to="/term#privacy">私隱條例</AnchorLink>
          </p>
        </span>
      </div>
      <div id="footer_center" className="footer_columns">
        <p>
          版權所有©2022 開心按揭服務有限公司
          <br />
          放債人牌照號碼 &nbsp;
          <a
            href="https://www.cr.gov.hk/tc/services/money-lenders/search/licensee-search.htm"
            target="__blank"
          >
            1334/2022
          </a>
        </p>
      </div>
      <div id="footer_right" className="footer_columns">
        <p>
          24小時申請熱線: &nbsp;
          <a href="tel:+85226406789" target="__blank">
            2640 6789
          </a>
          <br />
          WhatsApp 查詢: &nbsp;
          <a
            href="https://wa.me/85254899534?text=我有興趣了解按揭服務"
            target="__blank"
          >
            5489 9534
          </a>
        </p>
      </div>
    </div>
  )
}
