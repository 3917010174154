import React, { useState } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import logo from "../images/happy_mortgage_services_logo.png"
import BurgerOpen from "./burger_open"
import BurgerClose from "./burger_close"

import "./navbar.scss"

export default function Navbar() {
  const [open, setOpen] = useState(false)
  return (
    <div id="navbar">
      <div>
        <AnchorLink to="/">
          <img
            id="navLogo"
            src={logo}
            alt="Happy Mortgage Services Limited Logo"
          />
        </AnchorLink>
      </div>
      <div id="menu">
        <ul>
          <li>
            <AnchorLink to="/#main_company">公司簡介</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/service">貸款服務</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/application">申請辦法</AnchorLink>
          </li>
          <li>
            <AnchorLink to="/#main_contact">聯絡我們</AnchorLink>
          </li>
        </ul>
      </div>
      <BurgerOpen open={open} setOpen={setOpen} />
      <BurgerClose open={open} setOpen={setOpen} />
    </div>
  )
}
