import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Icon, InlineIcon } from "@iconify/react"
import menuLine from '@iconify/icons-clarity/menu-line';

import "./burger.scss"

export default function Burger({ open, setOpen }) {
  return (
    <div id="burger_close" open={open} onClick={() => setOpen(!open)}>
      <Icon icon={menuLine} style={{ color: "white", fontSize: "30px" }} />

      {/* <div
        style={{
          width: "20px",
          height: "3px",
          backgroundColor: "#fff",
          marginBottom: "2px",
        }}
      />
      <div
        style={{
          width: "20px",
          height: "3px",
          backgroundColor: "#fff",
          marginBottom: "2px",
        }}
      />
      <div
        style={{
          width: "20px",
          height: "3px",
          backgroundColor: "#fff",
          marginBottom: "2px",
        }}
      /> */}
    </div>
  )
}
